'use client'

import React, { type ComponentPropsWithoutRef, useEffect, useState, useContext } from 'react'
import clsx from 'clsx'
import Accordion from '@components/accordion'
import { richTextRenderFactory } from '@lib/rich-text-options'
import type {
  TypeExpandableContentFields,
  TypeExpandableContentItemFields,
} from '@lib/generated-types'
import slugify from 'slugify'
import { type Entry } from 'contentful'
import useAnalytics, { type SPContext } from '@hooks/use-analytics'
import { BlockContext, PageContext } from '@lib/analytics'

const ExpandableContent = ({
  internalName,
  sectionLink,
  items,
  className,
  ...props
}: ComponentPropsWithoutRef<'div'> & TypeExpandableContentFields) => {
  const richTextRenderChildren = richTextRenderFactory()

  const [targetSectionLink, setTargetSectionLink] = useState<string>('')
  const { track } = useAnalytics()

  // Extract the specific accordion target from the hash in the URL
  const setSectionLink = (hash: string) => {
    const hashSectionLink = hash.replace('#', '')
    setTargetSectionLink(hashSectionLink)
  }
  // wait until the window object is available to set the event listener for the hash change
  const windowReady = typeof window !== 'undefined';
  useEffect(() => {
    if(windowReady){
      window.addEventListener("hashchange", function(e){ 
        setSectionLink(window.location.hash)
      });
      setSectionLink(window.location.hash)
    }
  }, [windowReady])

  const targetOpen = (item: Entry<TypeExpandableContentItemFields>): boolean => {
    return item.fields.sectionLink === targetSectionLink
  }

  const pageContext = useContext(PageContext)
  const blockContext = useContext(BlockContext)

  return (
    <div
      id={sectionLink ? slugify(sectionLink) : undefined}
      className={clsx('container', className)}
      {...props}>
      <div>
        {items?.map((item) => {
          const contexts: SPContext[] = [
            {
              name: 'component',
              data: {
                component_text: item.fields.title,
              },
            },
          ]
          if (pageContext) {
            contexts.push(pageContext)
          }
          if (blockContext) {
            contexts.push(blockContext)
          }

          return (
            <Accordion
              key={item.sys.id}
              title={item.fields.title}
              onExpand={() =>
                track({
                  event: { name: 'component_expand', data: {} },
                  contexts: contexts,
                })
              }
              onCollapse={() =>
                track({
                  event: { name: 'component_collapse', data: {} },
                  contexts: contexts,
                })
              }
              isOpen={targetOpen(item)}
              id={item.fields.sectionLink ? slugify(item.fields.sectionLink) : undefined}>
              {richTextRenderChildren(item.fields.description)}
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}

export default ExpandableContent
