"use client"

import clsx from 'clsx'
import { ComponentPropsWithoutRef, ReactElement } from 'react'
import Heading from './heading'
import Analytics from '@components/analytics'

export interface CardProps extends ComponentPropsWithoutRef<'div'> {
  title: string
  summary?: string
  image?: ReactElement
  link?: string
  linkIsInternal?: boolean
  dataLayer?: any
}

const Card = ({
  title,
  summary,
  image,
  link,
  linkIsInternal = true,
  dataLayer,
  className,
  ...props
}: CardProps) => {
  const card = (
    <article>
      {image}
      <Heading level={6} size="h6" className={clsx('pt-4', link && 'group-hover:underline')}>
        {title}
      </Heading>
      {summary && <p className="pt-2 text-sm font-medium">{summary}</p>}
    </article>
  )
  return (
    
    <div className={className} {...props}>
      {link && (
        // eslint-disable-next-line react/jsx-no-target-blank
        <Analytics
          click={{
            name: 'component_click',
            data: {},
          }}
          contexts={[
            {
              name: 'component',
              data: {
                component_text: title,
                component_url: link,
              },
            },
          ]}>
          <a
            href={link}
            target={linkIsInternal ? undefined : '_blank'}
            rel={linkIsInternal ? undefined : 'noopener noreferrer'}
            className="outline-primary outline-offset-2 focus:outline-2">
            {card}
          </a>
        </Analytics>
      )}
      {!link && card}
    </div>
  )
}

export default Card
