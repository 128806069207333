"use client"

import clsx from 'clsx'
import { ptMap, pbMap, colWidthLeftMap } from '@lib/token-maps'
import Container from '@components/container'
import Heading from './heading'
import Analytics from '@components/analytics'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import CaptionedMedia from '@cms/components/captioned-media'
import { contentTypeBasePathMap } from '@lib/constants'
import slugify from 'slugify'
import { routeMapper } from '@lib/menu-item-mapper'
import type {
  TypeCardImageLongItemFields,
  TypeCardImageLongBlockFields,
  TypeNewsArticleFields,
} from '@lib/generated-types'

interface Fields {
  title: string
  summary: string
  image: any
}

const CardLong = ({
  internalName,
  sectionLink,
  cards,
  width,
  paddingTop,
  paddingBottom,
}: TypeCardImageLongBlockFields) => {

  return (

    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink)}
      className={clsx('grid grid-cols-12', ptMap[paddingTop], pbMap[paddingBottom])}
      >
      <div className={clsx(colWidthLeftMap[width], 'flex flex-row flex-wrap gap-x-5 gap-y-8 lg:gap-y-12')}>
        {cards.map(({ sys, fields }, index) => {
          const fieldsData = fields as Fields
          const contentType = sys.contentType.sys.id
          let preHeading: string | undefined, link: string, linkIsInternal: boolean
          if (contentType === 'cardImageLongItem') {
            const longFields = fields as TypeCardImageLongItemFields
            const { route, isInternal } = routeMapper(
              longFields.link,
              longFields.anchorOrExternalLink
            )
            link = route
            linkIsInternal = isInternal || false
            preHeading = longFields.preHeading
          } else {
            const newsFields = fields as TypeNewsArticleFields
            link = contentTypeBasePathMap[contentType] + newsFields.slug
            linkIsInternal = true
            preHeading = undefined
          }

          const cardLong = (
            <article className="flex flex-col gap-4 md:flex-row md:gap-8">
              <div>
                <CaptionedMedia
                  {...fieldsData.image.fields}
                  aspectRatio="4:3"
                  className="rounded md:rounded-none md:rounded-l overflow-hidden"
                  imageClassName={clsx(
                    'w-full',
                    'rounded',
                    'md:min-w-[286px]',
                    'md:w-[286px]',
                    'md:rounded-none',
                    'md:rounded-l',
                    'scale-100',
                    'duration-200',
                    link && 'group-hover:scale-105',
                    link && 'group-hover:origin-center',
                    link && 'group-hover:duration-200'
                  )}
                  extras={generateBaseMediaQWidths(4)}
                  showCaption={false}
                />
              </div>
              <div className="flex flex-col gap-2 justify-center md:pr-8 md:py-5">
                {preHeading && <p className="text-left text-sm font-semibold">{preHeading}</p>}
                <Heading level={3} size="h3" className={clsx('text-left', link && 'group-hover:underline')}>
                  {fieldsData.title}
                </Heading>
                {fieldsData.summary && <p className="text-left text-sm font-medium">{fieldsData.summary}</p>}
              </div>
            </article>
          );
          
          return (
            <Analytics
              key={index}
              click={{
                name: 'component_click',
                data: {},
              }}
              contexts={[
                {
                  name: 'component',
                  data: {
                    component_text: fieldsData.title,
                    component_url: link,
                  },
                },
                {
                  name: 'content',
                  data: {
                    content_type: contentType,
                    content_entry_id: sys.id,
                    content_level: 'item',
                    content_internal_name: internalName,
                  },
                },
              ]}>
              <div className={'group rounded w-full md:drop-shadow-md md:bg-gray-50'}>
                {link && (
                  <a
                    href={link}
                    target={linkIsInternal ? undefined : '_blank'}
                    rel={linkIsInternal ? undefined : 'noopener noreferrer'}
                    className="outline-primary outline-offset-2 focus:outline-2">
                    {cardLong}
                  </a>
                )}
                {!link && cardLong}
              </div>
            </Analytics>
          )
        })}
      </div>
    </Container>
  )
}

export default CardLong
