import Badge from '@cms/components/badge'
import type { Block, Inline } from '@contentful/rich-text-types'
import CaptionedMedia from '@cms/components/captioned-media'
import ExpandableContent from '@cms/components/expandable-content'
import List from '@cms/components/list'
import type { MediaQueryPercentageWidth } from '@lib/services/image-service'
import VideoPlayer from '@cms/components/video-player'
// All Embedded Entries get mapped here.
interface EmbeddedEntryRendererProps {
  embeddedEntry: Block | Inline
  extras?: MediaQueryPercentageWidth[]
  className?: string
}
export const EmbeddedEntryRenderer = ({
  embeddedEntry,
  extras,
  className,
}: EmbeddedEntryRendererProps): JSX.Element => {
  if (!embeddedEntry.data.hasOwnProperty('target')) {
    return <div className="hidden">Unpublished Entry</div>
  }
  // do a check for the string ending block
  const contentType = embeddedEntry.data.target.sys.contentType.sys.id
  const fields = { ...embeddedEntry.data.target.fields, extras, className }
  //Switch handles both ensuring the contentType is allowed, and calling the appropriate function
  switch (contentType) {
    case 'badge':
      return <Badge {...fields} />
    case 'captionedMedia':
      return <CaptionedMedia {...fields} />
    case 'expandableContent':
      return <ExpandableContent {...fields} />
    case 'list':
      return <List {...fields} />
    case 'videoPlayer':
      return <VideoPlayer {...fields} />
    default:
      return <div className="hidden">{contentType} is undefined in rendererMap</div>
  }
}
export default EmbeddedEntryRenderer
