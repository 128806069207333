import { ComponentPropsWithRef } from 'react'
import ReactContentfulImage, { setup as reactContentfulImageSetup } from 'react-contentful-image'

// The ContentfulImage component is based off of the open source library that renders a picture element and allows you to specify a collection of image sizes per media queries:
// Open source component: https://www.npmjs.com/package/react-contentful-image/v/1.3.2

// The Contentful Images API allows for the manipulation (resizing, cropping, compressing) of assets returned from the Contentful Delivery API.

//// CONFIGURATION SECTION - WHERE YOU SPECIFY THE BREAKPOINTS AND ANY VARIANTS YOU WANT AS A BASELINE

// Using Sharp's standard breakpoints defined in the DSM: https://sharphealthcare.invisionapp.com/dsm/sharp-health-care/dxp-redesign/nav/5fa7cb698c0120001835cd74/folder/60e8b857b69cf4312a5c45a8?mode=preview

const screenXs = '600px' // 640px  - (20px * 2) = 600px
const screenSm = '720px' // 768px  - (24px * 2) = 720px
const screenMd = '960px' // 1024px - (32px * 2) = 960px
const screenLg = '1140px' // 1280px - (70px * 2) = 1140px
const screenXl = '1396px' // 1536px - (70px * 2) = 1396px

export const media = {
  xs: `(min-width: ${screenXs})`,
  sm: `(min-width: ${screenSm})`,
  md: `(min-width: ${screenMd})`,
  lg: `(min-width: ${screenLg})`,
  xl: `(min-width: ${screenXl})`,
  dpr2: '(min-resolution: 144dpi)', // 1.5x devices and up get 2x images
  dpr3: '(min-resolution: 240dpi)', // 2.5x devices and up get 3x images
  portrait: '(orientation: portrait)',
  landscape: '(orientation: landscape)',
}

// The 'default' media query (no media query match) will automatically use variants 'dpr2' and 'dpr3' if they have not been removed. This means that for mobile devices that don't match a media query, if using the background image CSS function with the config of:

let variants = {
  default: {
    quality: 85,
    density: 1,
  },
  dpr2: {
    quality: 35,
    density: 2,
  },
  dpr3: {
    quality: 25,
    density: 3,
  },
}

// This call to the setup ONLY confugures the preset media queries and variants and makes them AVAILABLE; you still have to specify which breakpoints what image API paramaters / variants you want for a particular class of images
reactContentfulImageSetup(media, variants)

// The ContentfulImagesAPI interface is a direct representation of the Contentful Images API found here: https://www.contentful.com/developers/docs/references/images-api/
export interface ContentfulImagesAPI {
  w?: number // Width: max allowed value is 4000 px
  h?: number // Width: max allowed value is 4000 px
  fm?: 'jpg' | 'png' | 'webp' | 'gif' | 'avif' // Image format
  fl?: 'progressive' | 'png8' // Progressive JPEGS
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb' // images are resized to fit into the specified dimensions by default
  f?:
    | 'center'
    | 'top'
    | 'right'
    | 'left'
    | 'bottom'
    | 'top_right'
    | 'top_left'
    | 'bottom_right'
    | 'bottom_left'
    | 'face'
    | 'faces' // for all the faces detected.
  r?: number | 'max' // Rounded corners (radius in px)
  q?: number // Quality (1-100)
}

export interface ImageSizes {
  mediaQuery: string
  params: ContentfulImagesAPI
  variants?: string[]
}

// The imageSizes variable is used to specify the image size you would like per media query
// Please note: if you specify only one dimension (either width (w) or height (h), you also have to include a fit parameter (in this case, the fill value 'fill'....))

//// COMPONENT SECTION
export interface ContentfulImageProps extends ComponentPropsWithRef<'picture'> {
  src?: string
  imageSizes?: ImageSizes[]
  alt?: string
  className?: string
  title?: string
}

/**
 * DEPRECATED. Use ResponsiveImage or Next/Image instead.
 */
const ContentfulImage = ({ src = '', imageSizes, alt, className, title }: ContentfulImageProps) => {
  return (
    <ReactContentfulImage
      src={src}
      sizes={imageSizes}
      className={className}
      alt={alt}
      title={title}
    />
  )
}

export default ContentfulImage
