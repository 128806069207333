"use client"

import { Icon, Typography } from '@shc/ui'
import clsx from 'clsx'
import { type ComponentPropsWithoutRef } from 'react'
import { getIcon } from './icons'
import Analytics from '@components/analytics'

export interface CardIconProps extends ComponentPropsWithoutRef<'div'> {
  icon?: string
  title: string
  summary: string
  linkLabel?: string
  link?: string
  linkIsInternal?: boolean
  clickable?: boolean
}

const CardIcon = ({
  icon,
  title,
  summary,
  linkLabel,
  link,
  clickable = false,
  linkIsInternal,
  className,
  ...props
}: CardIconProps) => {
  const isClickable = clickable || !!link
  const card = (
    <article className="flex flex-col items-center justify-start gap-6 p-6 h-full">
      {icon && <Icon icon={getIcon(icon)} className="text-primary h-6" />}
      {title && (
        <Typography variant="h3" align="center">
          {title}
        </Typography>
      )}
      {summary && <div className="text-base font-medium leading-7 text-center">{summary}</div>}
      {linkLabel && (
        <div className="flex flex-col items-center justify-start gap-6 w-full mt-auto">
          <hr className="w-full h-1 text-gray-50" />
          <div className="text-primary font-bold group-hover:underline">{linkLabel}</div>
        </div>
      )}
    </article>
  )

  const containerStyle = clsx(
    'bg-gray-50 rounded drop-shadow-md h-full border-2 border-gray-50',
    isClickable &&
      'hover:border-2 hover:border-primary hover:transition-color hover:duration-300 hover:ease-out group cursor-pointer'
  )

  if (isClickable) {
    const Element = link ? 'a' : 'button'
    const attributes = link
      ? {
          href: link,
          target: linkIsInternal ? undefined : '_blank',
          rel: linkIsInternal ? undefined : 'noopener noreferrer',
        }
      : { 'aria-label': linkLabel }
    return (
      <Analytics
        click={{
          name: 'component_click',
          data: {},
        }}
        contexts={[
          {
            name: 'component',
            data: {
              component_text: linkLabel ?? title,
              component_url: link ?? '',
            },
          },
        ]}>
        <div className={className} {...props}>
          <div className={containerStyle}>
            <Element {...attributes} className="outline-primary">
              {card}
            </Element>
          </div>
        </div>
      </Analytics>
    )
  }

  return (
    <div className={className} {...props}>
      <div className={containerStyle}>{card}</div>
    </div>
  )
}

export default CardIcon
