"use client"

import clsx from 'clsx'
import { ComponentPropsWithoutRef, ReactElement } from 'react'
import Heading from '@components/heading'
import { dataLayerMergePush } from '@lib/services/gtm-service'
export interface BioProps extends ComponentPropsWithoutRef<'div'> {
  image?: ReactElement
  heading: string
  subHeading?: string
  body?: string
  link?: string
  dataLayer?: any
}

const Bio = ({
  image,
  heading,
  subHeading,
  body,
  link,
  dataLayer,
  className,
  ...props
}: BioProps) => {

  const onClick = () => {
    dataLayerMergePush(dataLayer)
  }
  
  return (
    <div className={clsx('flex flex-row gap-5', className)} {...props}>
      {link ? (
        <>
          <a
            onClick={onClick}
            href={link}
            aria-label={`Link to ${heading}'s Bio`}
            className="outline-primary outline-offset-2 focus:outline-2">
            {image}
          </a>
          <a
            onClick={onClick}
            href={link}
            aria-label={`Link to ${heading}'s Bio`}
            className="outline-primary outline-offset-2 focus:outline-2">
            <div className="flex flex-col gap-3">
              <Heading level={3} size="h3" className="group-hover:underline">
                {heading}
              </Heading>
              {subHeading && <div className="text-sm font-semibold">{subHeading}</div>}
              {body && <p className="text-sm font-medium">{body}</p>}
            </div>
          </a>
        </>
      ) : (
        <>
          {image}
          <div className="flex flex-col gap-3">
            <Heading level={3} size="h3">
              {heading}
            </Heading>
            {subHeading && <div className="text-sm font-semibold">{subHeading}</div>}
            {body && <p className="text-sm font-medium">{body}</p>}
          </div>
        </>
      )}
    </div>
  )
}

export default Bio
