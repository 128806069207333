import { type ComponentPropsWithoutRef } from 'react'
import omit from 'lodash/omit'
import type { TypeCaptionedMediaFields } from '@lib/generated-types'
import type { MediaQueryPercentageWidth } from '@lib/services/image-service'
import CaptionedMedia from '@components/captioned-media'
import { richTextRender } from '@lib/rich-text-base'
import { imageAssetMapper } from '@lib/image-asset-mapper'
import type { ContentfulImagesAPI, ImageSizes } from '@components/contentful-image'
import { aspectRatioMap } from '@lib/token-maps'

export interface CaptionedMediaProps
  extends TypeCaptionedMediaFields,
    ComponentPropsWithoutRef<'figure'> {
  extras: MediaQueryPercentageWidth[] | undefined
  imageClassName?: string
  showCaption?: boolean
}

const CaptionedMediaCms = ({
  focalPoint,
  image,
  caption,
  aspectRatio = '16:9',
  imageClassName,
  extras,
  showCaption = true,
  ...props
}: CaptionedMediaProps) => {
  let aspectRatioVal: number | undefined = aspectRatio ? aspectRatioMap[aspectRatio] : undefined

  const captionedImageSizes: ImageSizes[] | undefined =
    extras &&
    extras.map((value) => {
      return {
        mediaQuery: value.mediaQuery,
        params: {
          w: value.w,
          h: aspectRatioVal ? Math.ceil(value.w / aspectRatioVal) : undefined,
          fit: 'fill',
          f: focalPoint?.toLowerCase() as Lowercase<NonNullable<ContentfulImagesAPI['f']>>,
        },
      }
    })

  return (
    <CaptionedMedia
      image={imageAssetMapper(image)}
      imageSizes={captionedImageSizes}
      imageClassName={imageClassName}
      {...omit(props, ['internalName'])}>
      {showCaption && richTextRender(caption)}
    </CaptionedMedia>
  )
}

export default CaptionedMediaCms
