import type {
    TypeMenuItem,
    TypePageFields,
  } from './generated-types'
  import type { RouteMetadata, MenuItem, MenuItemData, Json } from './types'
  import { contentTypeBasePathMap, indexPageSlug } from './constants'
  import * as Contentful from 'contentful'
  
  export const isInternalHref = (href: string | undefined): boolean => {
    if (typeof href === 'undefined' || href === '') {
      return false
    }
    // https://jex.im/regulex/#!flags=i&re=%5E(%3F!(%3F%3Aht%7Cf)tp%7C%3A%7C%5C%2F%5C%2F)%5B%5E.%5D*(%5C%2F.*)%3F%24
    return href.match(/^(?!(?:ht|f)tp|:|\/\/)[^.]+(\/.*)?$/i) !== null
  }
  
  export const routeMapper = (
    link?: Contentful.Entry<TypePageFields>,
    anchorOrExternalLink?: string
  ): RouteMetadata => {
    let isInternal = false
    let isAppInternal = false
    let route = ''
  
    if (link) {
      const basePath = contentTypeBasePathMap[link.sys.contentType.sys.id]
      const slug = link.fields.slug === indexPageSlug ? '' : link.fields.slug
      route = basePath + slug
      isInternal = true
      isAppInternal = true
    } else {
      route = anchorOrExternalLink ?? ''
      isInternal = isInternalHref(route)
    }
  
    return {
      route,
      isInternal,
      isAppInternal,
    }
  }
  
  export const menuItemMapper = (value: TypeMenuItem): MenuItem => {
    // Early return when `fields` is not defined; https://github.com/contentful/contentful.js/issues/427
    if (!value.fields) return [{ id: '', name: '', route: '', isInternal: false }, []]
  
    const children: MenuItem[] = value.fields.items ? value.fields.items.map(menuItemMapper) : []
  
    const { route, isInternal, isAppInternal } = routeMapper(
      value.fields.link,
      value.fields.anchorOrExternalLink
    )
  
    let data: MenuItemData = {
      id: value.sys.id,
      name: value.fields.title,
      route,
      isInternal,
      isAppInternal,
    }
  
    /**
     * NextJS barfs on serializing undefined :face-palm:
     * we will be careful not to add the key if the value is undefined
     * https://github.com/vercel/next.js/discussions/11209#discussioncomment-1745088
     *
     * For MVP people will be typing in icon names so let
     * us handle accidental whitespace.
     */
    if (
      typeof value.fields.icon !== 'undefined' &&
      typeof value.fields.icon.fields.icon === 'string' &&
      value.fields.icon.fields.icon.trim().length > 0
    ) {
      data.icon = value.fields.icon.fields.icon.replace(/\s/g, '').trim()
    }
  
    if (typeof value.fields.dataLayer !== 'undefined') {
      data.dataLayer = value.fields.dataLayer as Json
    }
  
    return [data, children]
  }
  
  export default menuItemMapper
  