import { ComponentPropsWithoutRef } from 'react'
import ContentfulImage, { ImageSizes } from './contentful-image'
import { RawImageType } from '@lib/types'
import clsx from 'clsx'

export interface CaptionedMediaProps extends ComponentPropsWithoutRef<'figure'> {
  image?: RawImageType
  imageSizes?: ImageSizes[]
  imageClassName?: string
}

const CaptionedMedia = ({
  image,
  imageSizes,
  imageClassName,
  className,
  children,
  ...props
}: CaptionedMediaProps) => {

  return (
    <figure 
    className={className} {...props}>
      <ContentfulImage
        src={image?.file.url}
        imageSizes={imageSizes}
        alt={image?.description ? image?.description : image?.title}
        className={clsx('object-cover w-full', imageClassName)}
      />

      <figcaption className="text-sm text-left mt-3 empty:hidden">{children}</figcaption>
    </figure>
  )
}

export default CaptionedMedia
