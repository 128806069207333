"use client"

import CountUp from 'react-countup'
import { Icon } from '@shc/ui'
import { type IconContentType, getIcon } from '@components/icons'
import { ComponentPropsWithoutRef } from 'react'
import Heading from './heading'

export interface CardStatProps extends ComponentPropsWithoutRef<'div'> {
  icon: string
  iconType?: IconContentType
  prefix?: string
  startingValue?: number
  finalValue: number
  suffix?: string
  summary?: string
}

const CardStat = ({
  icon,
  iconType,
  prefix = '',
  finalValue,
  startingValue = finalValue,
  suffix = '',
  summary,
  className,
  ...props
}: CardStatProps) => {
  return (
    <div className={className} {...props}>
      <div className="p-10 bg-gray-50 rounded drop-shadow-md h-full">
        <article className="flex flex-col items-center justify-center">
          {icon && <Icon icon={getIcon(icon, iconType)} className="text-primary h-8 pb-3" />}
          <CountUp
            start={startingValue}
            end={finalValue}
            prefix={prefix}
            suffix={suffix}
            separator=","
            enableScrollSpy>
            {({ countUpRef }) => (
              <div>
                <span className="text-primary text-5xl leading-[50px] font-bold" ref={countUpRef} />
              </div>
            )}
          </CountUp>
          {summary && (
            <Heading level={6} size="h6" className="text-center">
              {summary}
            </Heading>
          )}
        </article>
      </div>
    </div>
  )
}

export default CardStat
